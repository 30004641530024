import styled from "styled-components";
import Link from "./link";

// Use as="a" for external links
const LinkButton = styled(Link)`
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  font-family: "display-lucky", "Helvetica", sans-serif;
  padding: 14px 20px;
  transition: ${p => p.theme.transitionDefault};

  ${p => p.bg === `#30B842`
    && `
    &:hover {
      background-color: #2FA73D;
    }
    `}

    ${p => p.bg === `#0088E9`
      && `
    &:hover {
      background-color: #027bd2;
    }
    `}

    ${p => p.bg === `#F7A600`
      && `
    &:hover {
      background-color: #e59a02;
    }
    `}

    ${p => p.bg === `#F46A01`
    && `
    &:hover {
      background-color: #E86501;
    }
    `}
`;

LinkButton.defaultProps = {
  color: `#ffffff`,
  bg: `#30B842`,
};

export default LinkButton;
