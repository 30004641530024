import React from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components";

import Banner from "../containers/banner";
import Circle from "../globals/circle";
import MaxWidthAbsolute from "../containers/maxWidthAbsolute";

const BlogBanner = ({ children, theme }) => {
  return (
    <Banner
      bg={theme.green}
    >
      <MaxWidthAbsolute>
        <Circle
          grow
          bgColor={theme.blueDark}
          opacity="0.1"
          bottom={[60, null, -46, -90]}
          left={[-30, null, -46, -90]}
        />
        <Circle
          size="m"
          bgColor={theme.blueDark}
          opacity="0.1"
          top={[-45, null, -23, -45]}
          left="24%"
        />
        <Circle
          size="l"
          bgColor={theme.blueDark}
          opacity="0.1"
          bottom={[45, null, -20]}
          right="18%"
          hideOnMobile
        />
        <Circle
          grow
          bgColor={theme.blueDark}
          opacity="0.1"
          top={[60, null, -46, -90]}
          right={[-30, null, -46, -90]}
        />
      </MaxWidthAbsolute>

      {children}
    </Banner>
  );
};

BlogBanner.propTypes = {
  children: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(BlogBanner);
