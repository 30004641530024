import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from "styled-components";

import Heading from "../typography/heading";
import Text from "../typography/text";
import Link from "../links/link";
import Image from "../globals/image";
import Box from "../containers/box";
import { buildDate } from "../../utils/helperFunctions";

const Root = styled.li`
  width: 100%;

  h2 {
    margin-top: 16px;
    margin-bottom: 44px;
  }

  a {
    display: flex;
    flex-direction: column;
    height: 100%;

    span {
      margin-top: auto;
    }

    &:hover {
      span:last-of-type {
        color: ${p => p.theme.greenDark};
      }
    }
  }
`;

const DateWrap = styled(Box)`
  color: ${p => p.theme.greyDark};
  font-size: ${p => p.theme.rem16};
`;

const ImgCrop = styled(Box)`
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 */

  > * {
    /* important needed to override default Gatsby image inline styles */
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

const BlogListItem = ({ data, theme }) => {
  const { fluid } = data.node.data.image.localFile.childImageSharp;

  return (
    <Root>
      <Link
        to={`/blog/${data.node.uid}`}
        color={theme.green}
        fontSize={theme.rem18}
      >
        <ImgCrop>
          <Image fluid={fluid} alt={data.node.data.image.alt} />
        </ImgCrop>

        {data.node.data.date && (
          <DateWrap pt="30px">
            {buildDate(data.node.data.date)}
          </DateWrap>
        )}

        <Heading as="h2" fontSize="30px" lineHeight="38px">
          {data.node.data.title.text}
        </Heading>

        <Text as="span" variant="readMore" mb="0">Read more</Text>
      </Link>
    </Root>
  );
};

BlogListItem.propTypes = {
  data: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(BlogListItem);
