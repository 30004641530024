import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";

import SEO from "../components/globals/seo";
import Layout from "../components/containers/layout";
import Section from "../components/containers/section";
import Container from "../components/containers/container";
import Flex from "../components/containers/flex";
import Box from "../components/containers/box";
import BlogList from "../components/blog/blogList";
import BlogBanner from "../components/banners/blogBanner";
import Heading from "../components/typography/heading";
import Text from "../components/typography/text";
import Link from "../components/links/link";
import Image from "../components/globals/image";
import LinkButton from "../components/links/linkButton";
import { truncateString, buildDate } from "../utils/helperFunctions";

const ActiveImgWrap = styled(Box)`
  img {
    width: 100%;
    max-width: 800px;
    border-radius: ${p => p.theme.borderRadiusSqBtn};
  }
`;

const FeaturedPostWrap = styled(Link)`
  display: block;
  transition: ${p => p.theme.transitionDefault};

  &:hover {
    cursor: pointer;

    .readMore {
      color: ${p => p.theme.greenDark};
    }
  }
`;

const DateWrap = styled(Box)`
  color: ${p => p.theme.greyDark};
  font-size: ${p => p.theme.rem16};
`;

const BannerContainer = styled(Container)`
  padding-top: 60px;
  padding-bottom: 80px;  

  @media (min-width: ${p => p.theme.screenS}) {
    padding-top: 84px;
    padding-bottom: 150px;  
  }
`;

const BlogListing = ({ theme, data, pageContext }) => {
  console.log(data);

  // The post are ordered by date during graphql query below
  const featuredPost = data.featuredPost.edges[0].node;
  const blogListingQuery = data.blogListing.edges.filter((item, index) => {
    return index !== 0;
  });
  // gatsby-node createPages context destructuring
  const { numPages, currentPage } = pageContext;

  return (
    <Layout navbarPadOnly>
      <SEO
        title="Blog"
        description="Find all the latest stories on TopTekkers!"
        path={`/blog/${currentPage || ``}`}
      />

      {/* Banner */}
      <BlogBanner>
        <BannerContainer>
          <Heading
            as="h1"
            color={theme.white}
            fontSize={[`38px`, null, `48px`]}
            mb="30px"
          >
            Blog
          </Heading>
        </BannerContainer>
      </BlogBanner>

      {/* Highlight */}
      <Section pt={[30, null, 87]} pb={[40, null, 70]}>
        <Container>
          <FeaturedPostWrap to={`/blog/${featuredPost.uid}`}>
            <Flex
              justifyContent="space-between"
              flexDirection={[`column`, null, `row`]}
              alignItems="flex-start"
            >
              {/* content */}
              <Box
                mr={[0, null, 30]}
                mb={[20, null, 0]}
                width={[`100%`, null, `40%`]}
                maxWidth={[null, null, 474]}
                order={[2, null, 1]}
              >
                {featuredPost.data.date && (
                <DateWrap mb={[`20px`, null, `26px`]}>
                  {buildDate(featuredPost.data.date)}
                </DateWrap>
                )}
                <Heading as="h2" fontSize="30px" lineHeight="38px">
                  {featuredPost.data.title.text}
                </Heading>
                <Text>{truncateString(featuredPost.data.content.text, 180)}</Text>
                <Text className="readMore" variant="readMore">
                  Read more
                </Text>
              </Box>

              {/* image */}
              <ActiveImgWrap
                width={[`100%`, null, `60%`]}
                mt={[`-70px`, null, 0, `-120px`]}
                mb={[`30px`, null, 0]}
                order={[1, null, 2]}
              >
                <Image
                  fluid={
                    featuredPost.data.image.localFile.childImageSharp.fluid
                  }
                  alt={featuredPost.data.image.alt}
                />
              </ActiveImgWrap>
            </Flex>
          </FeaturedPostWrap>
        </Container>
      </Section>

      <Section>
        <Container>
          <BlogList listItems={blogListingQuery} />

          <Flex mt="40px" justifyContent={currentPage === 1 ? `flex-end` : `space-between`}>
            {currentPage > 1
              && <LinkButton to={currentPage > 2 ? `/blog/${currentPage - 1}` : `/blog`}>Previous</LinkButton>
            }
            {currentPage !== numPages
              && <LinkButton to={`/blog/${currentPage + 1}`}>Next</LinkButton>
            }
          </Flex>
        </Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
    query($skip: Int!, $limit: Int!) {
      blogListing: allPrismicBlog(
      filter: {data: {hide: {ne: true}}},
      sort: { fields: data___date, order: DESC },
      limit: $limit,
      skip: $skip
    ) {      
      edges {
        node {
          uid
          data {
            date
            title {
              text
            }
            image {
              alt
              localFile {
                childImageSharp {
                  fluid(maxWidth: 370) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
    featuredPost: allPrismicBlog(
      filter: {data: {hide: {ne: true}}},
      sort: { fields: data___date, order: DESC },
      limit: 1,
      skip: $skip
      ){
      edges {
        node {
          uid
          data {
            hide
            date
            title {
              text
            }
            content {
              text
            }
            image {
              alt
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
   }
`;

BlogListing.propTypes = {
  theme: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default withTheme(BlogListing);
