import styled from "styled-components";

import { Absolute } from "./position";

const MaxWidthAbsolute = styled(Absolute)``;

MaxWidthAbsolute.defaultProps = {
  maxWidth: `1600px`,
  mx: `auto`,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

export default MaxWidthAbsolute;
