import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

import BlogListItem from "./blogListItem";

const Root = styled.ul` 
  display: grid;
  grid-column-gap: 45px;
  grid-row-gap: 54px;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: ${p => p.theme.screenS}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: ${p => p.theme.screenL}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const BlogList = ({ listItems }) => {
  return (
    <Root>
      {listItems.map((post) => {
        return (
          <BlogListItem key={post.node.uid} data={post} />
        );
      })
  }
    </Root>
  );
};

BlogList.propTypes = {
  listItems: PropTypes.array.isRequired,
};

export default BlogList;
